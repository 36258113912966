<mat-card class="u-margin__bottom--normal">
  <div class="title">{{ alert.eventType.name }}</div>
  <div class="description">
    <span class="u-text--semibold">{{ typeOptionsMap[alert.eventType.id] }}</span> for
    <span class="u-text--semibold">{{ alert.allAssets ? assetsTotal : alert.entities.length }} assets</span> to
    <span class="u-text--semibold">{{ alert.recipients.length }} recipients</span> via <mat-icon>mail_outline</mat-icon>
  </div>
  <div class="status">Status: {{ alert.status }}</div>
  <div *ngIf="isAdmin$ | async" class="flex flex-row justify-end" data-e2e="edit-button">
    <button mat-stroked-button color="accent" [routerLink]="['edit', alert.subscription_id]">Edit</button>
  </div>
</mat-card>
