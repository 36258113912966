import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  saveErrorText,
  saveSuccessText,
  deleteErrorText,
  deleteSuccessText,
  COMPANIES_ENTITY_TYPE,
  TOAST_MESSAGE_DURATION_SECONDS,
  UNAUTHORIZED_USAGE_ALERT_TYPE
} from '@app/constants';
import { Alert, Company } from '@app/models';
import { SubscriptionApiHttpService } from '@app/services/subscription-api.http.service';
import { AssetApiHttpService } from '@app/services/asset-api.http.service';
import { RecipientApiHttpService } from '@app/services/recipient-api.http.service';
import { CompanyService } from '@app/services/company-store.service';
import { ReplaySubject, Subject, of, throwError } from 'rxjs';
import { catchError, finalize, filter, map, switchMap, take, startWith, takeUntil } from 'rxjs/operators';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CustomScheduleDialogComponent } from '../custom-schedule-dialog/custom-schedule-dialog.component';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { TIME_OPTIONS } from '@app/constants';
import * as moment from 'moment-timezone';
import { ENV } from '@environments/environment.provider';
import { AdminService } from '@app/services/admin.service';

@Component({
  selector: 'app-alert-subscription-form',
  templateUrl: './alert-subscription-form.component.html',
  styleUrls: ['./alert-subscription-form.component.scss']
})
export class AlertSubscriptionFormComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(ENV) private env: any,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _subscriptionApiService: SubscriptionApiHttpService,
    private _assetApiService: AssetApiHttpService,
    private _recipientApiService: RecipientApiHttpService,
    private _companyService: CompanyService,
    private _notifications: ZonarUiNotificationsService,
    private _router: Router,
    private _dialog: MatDialog,
    private _adminService: AdminService,
    private _featureToggleService: FeatureToggleService
  ) {}
  @Input() alert: Alert;
  public alertForm: FormGroup;
  id: string;
  companyId: string;
  isAddMode: boolean;

  isSaving: boolean = false;
  isDropdownLoadingError = false;

  displayCustomSchedule;
  displayCTSLink;

  assets$ = new ReplaySubject<any[]>(1);
  isManagedThresholds$ = this._adminService.isManagedThresholds$;

  companyId$ = this._companyService.currentCompany$.pipe(map((company) => company.value));

  unauthorizedUsageAlertTypeValue = UNAUTHORIZED_USAGE_ALERT_TYPE;
  thresholdSettingsLink: string = this.env.thresholdSettingsBase.link;

  additionalSeverityOptionsInfo: Record<string, string>[] = [
    { title: 'Critical events', icon: 'info' },
    { title: 'Minor + critical events', icon: 'report_problem' }
  ];

  // NOTE: needs to be updated each time a new alert type with radio button is added
  alertTypesWithRadioButtons = [
    'ev_soc_low',
    'low_battery',
    'fault_code',
    'low_battery_test_270',
    'low_battery_test',
    'depth_level_test_270',
    'evir-defect'
  ];

  get name() {
    return this.alertForm.get('name');
  }

  preselectedAssets$ = new ReplaySubject<any[]>(1);
  preselectedAssetsObs$ = this.preselectedAssets$.asObservable().pipe(filter((val) => !!val));

  defaultBlankFormValues = {
    name: '',
    type: null,
    assets: [],
    recipients: null,
    isActive: true,
    severity: '',
    startTime: '',
    endTime: '',
    allAssets: false,
    unauthorizedUsageDistance: '0'
  };

  assetOptions$ = this._companyService.currentCompany$.pipe(
    switchMap(() => {
      return this._assetApiService.getAssetsFromCache().pipe(
        map((assets) => {
          this.assets$.next(assets);
          const assetOptions = [];
          assets.forEach((asset) => {
            assetOptions.push({ title: asset.name, value: asset.id });
          });
          return assetOptions;
        }),
        catchError((err) => {
          this.isDropdownLoadingError = true;
          return throwError(err);
        })
      );
    })
  );

  recipients$ = this._companyService.currentCompany$.pipe(
    switchMap(() => {
      return this._recipientApiService.getRecipients().pipe(
        map((recipients) => {
          const recipientOptions = [];
          recipients.forEach((recipient) => {
            recipientOptions.push({ title: recipient.name, value: recipient.userId });
          });
          return recipientOptions;
        }),
        catchError((err) => {
          this.isDropdownLoadingError = true;
          return throwError(err);
        })
      );
    })
  );

  alertTypes$ = this._companyService.currentCompany$.pipe(
    switchMap((company) => this._subscriptionApiService.getAllAlertTypes(company.value)),
    map((alertTypes) =>
      alertTypes.results.reduce(
        (acc, alertType) => {
          let severityOptionsByType = acc.severityOptionsByType;
          let isThresholdUrlShowedByType = acc.isThresholdUrlShowedByType;
          if (alertType._source.event_selection && alertType._source.event_selection.length) {
            // Case: List of radio buttons
            const severitySelectors = alertType._source.event_selection.find((item) => item.field === 'severity').selector;
            const severityOptions = {
              [alertType._source.event_type]: severitySelectors.map((selector, index) => ({
                title: this.additionalSeverityOptionsInfo[index].title,
                description: selector.name,
                value: selector.id,
                icon: this.additionalSeverityOptionsInfo[index].icon
              }))
            };
            const isThresholdUrlShowed = { [alertType._source.event_type]: this.isAlertManagedByThresholdSetting(severitySelectors) };
            severityOptionsByType = { ...severityOptionsByType, ...severityOptions };
            isThresholdUrlShowedByType = { ...isThresholdUrlShowedByType, ...isThresholdUrlShowed };
          }

          return {
            alertTypeOptions: [...acc.alertTypeOptions, ...[{ title: alertType._source.name, value: alertType._source.event_type }]],
            severityOptionsByType: severityOptionsByType,
            alertDescriptionByType: { ...acc.alertDescriptionByType, [alertType._source.event_type]: alertType._source.description },
            isThresholdUrlShowedByType: isThresholdUrlShowedByType
          };
        },
        { alertTypeOptions: [], severityOptionsByType: {}, alertDescriptionByType: {}, isThresholdUrlShowedByType: {} }
      )
    ),
    catchError((err) => {
      this.isDropdownLoadingError = true;
      return throwError(err);
    })
  );

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this._featureToggleService.initializeDevCycle();
    this._featureToggleService
      .isFeatureEnabled('am-custom-schedule')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((flag) => (this.displayCustomSchedule = flag));
    this._featureToggleService
      .isFeatureEnabled('cts-admin-link')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((flag) => (this.displayCTSLink = flag));

    this.isAddMode = !this.id;
    this._populateForm(this.defaultBlankFormValues);
    if (!this.isAddMode) {
      this._subscriptionApiService
        .getSubscriptionById(this.id)
        .pipe(
          switchMap((alert) => {
            const selectedAlert = alert[0];
            if (selectedAlert.allAssets) {
              return this._assetApiService.getAssets().pipe(
                map((assets) => {
                  const transformedAssets = this._buildSavedDropdownOptionsList(assets);
                  this.preselectedAssets$.next(transformedAssets);
                  return { ...selectedAlert, entities: assets };
                })
              );
            } else {
              return of(selectedAlert).pipe(
                map((selectedAlert) => {
                  const assets = this._buildSavedDropdownOptionsList(selectedAlert.entities);
                  this.preselectedAssets$.next(assets);
                  return selectedAlert;
                })
              );
            }
          }),
          take(1)
        )
        .subscribe((alert) => {
          const alertValues = this._buildFormWithExistingValues(alert);
          this._populateForm(alertValues);
          this._subscribeToTypeFormControlValueChanges(alert);
        });
    } else {
      this.preselectedAssets$.next([]);
      this._subscribeToTypeFormControlValueChanges(null);
    }
  }

  private isAlertManagedByThresholdSetting(selectors) {
    const lookupSettingKey = (logicSelector) => {
      if (logicSelector.settingsAPIKey) {
        return true;
      }

      if (Array.isArray(logicSelector.iterator)) {
        return logicSelector.iterator.some((selector) => lookupSettingKey(selector));
      }

      return false;
    };

    return selectors.some((selector) => lookupSettingKey(selector.comparisonLogics));
  }

  private _subscribeToTypeFormControlValueChanges(alert) {
    let startingValue;
    if (!!alert) {
      startingValue = alert.eventType.id;
    } else {
      startingValue = this.alertForm.value.type;
    }

    this.alertForm.controls['type'].valueChanges
      .pipe(
        startWith(startingValue),
        map((value) => this.addOrRemoveControlsBasedOnTypeSelection(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _convertUTCToLocal(day: string, time: string, timezone: string): string {
    const weekDay = moment().day(day).format('YYYY-MM-DD'); // Get the specific date for this week's day
    const dateTimeFormat = `${weekDay} ${time}`;
    const utcMoment = moment.tz(dateTimeFormat, 'YYYY-MM-DD hh:mm A', 'UTC');
    const localMoment = utcMoment.clone().tz(timezone);

    // Find the closest match in TIME_OPTIONS
    const formattedTime = localMoment.format('h:mm A');
    const closestTime = this._findClosestTimeOption(formattedTime);
    return `${localMoment.format('dddd')} ${closestTime}`;
  }

  private _findClosestTimeOption(time: string): string {
    // This function can be improved to find the closest match in TIME_OPTIONS
    // For simplicity, it returns the exact match or the first option as a fallback
    return TIME_OPTIONS.includes(time) ? time : TIME_OPTIONS[0];
  }

  private _buildFormWithExistingValues(alert) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formObj: any = {
      name: alert.eventType.name,
      type: alert.eventType.id,
      assets: this._buildSavedDropdownOptionsList(alert.entities),
      recipients: this._buildSavedDropdownOptionsList(alert.recipients),
      isActive: alert.status === 'ACTIVE',
      startTime: alert.customSchedule?.customStartTime
        ? this._convertUTCToLocal(alert.customSchedule.customStartTime.day, alert.customSchedule.customStartTime.time, userTimezone)
        : '',
      endTime: alert.customSchedule?.customEndTime
        ? this._convertUTCToLocal(alert.customSchedule.customEndTime.day, alert.customSchedule.customEndTime.time, userTimezone)
        : '',
      allAssets: alert.allAssets
    };

    if (alert.eventType.id === this.unauthorizedUsageAlertTypeValue) {
      formObj.unauthorizedUsageDistance = alert.configParams.distanceTravelled;
    } else if (alert.eventSelection && alert.eventSelection.length) {
      formObj.severity = alert.eventSelection?.find((item) => item.field === 'severity').activeSelectorId;
    }

    return formObj;
  }

  handleToggleStatusChange() {
    // TODO: toggle slider is not marking the form as touched automatically. Investigate why.
    this.alertForm.markAsTouched();
  }

  private _populateForm(values) {
    this.alertForm = this.fb.group({
      name: [values.name, [Validators.required, Validators.maxLength(50)]],
      type: [values.type, Validators.required],
      assets: [values.assets, Validators.required],
      recipients: [values.recipients, Validators.required],
      isActive: [values.isActive],
      severity: [values.severity, Validators.required],
      startTime: [values.startTime],
      endTime: [values.endTime],
      allAssets: [values.allAssets],
      unauthorizedUsageDistance: [values.unauthorizedUsageDistance]
    });
  }

  private _buildSavedDropdownOptionsList(optionsList) {
    const optionIdsList = [];
    optionsList.forEach((option) => {
      // TODO: Remove this when https://zonarsystems.atlassian.net/browse/AA-611 has been completed
      if (option.type !== COMPANIES_ENTITY_TYPE) {
        optionIdsList.push(option.id);
      }
    });
    return optionIdsList;
  }

  submit() {
    this.isSaving = true;

    this._companyService.currentCompany$
      .pipe(
        switchMap((company: Company) => {
          this.companyId = company?.value;
          return this.assetOptions$.pipe(
            map((assetOptions) => {
              const alert = this.alertForm.getRawValue();
              if (!alert.allAssets) {
                // allAssets value is true if number of selected assets equals total number of assets
                // double-checks whether all assets have been manually selected, as opposed to checking the All Assets checkbox
                alert.allAssets = alert.assets.length === assetOptions.length;
              }
              return alert;
            })
          );
        }),
        switchMap((alert) => {
          return this.isAddMode
            ? this._subscriptionApiService.post(alert, this.companyId)
            : this._subscriptionApiService.put(this.id, alert, this.companyId);
        }),
        take(1),
        finalize(() => (this.isSaving = false))
      )
      .subscribe(
        () => {
          this.alertForm.reset();
          this.showSuccessNotification(saveSuccessText);
          this._router.navigate(['/']);
        },
        () => {
          this.showErrorNotification(saveErrorText);
        }
      );
  }

  deleteAlert() {
    const dialogRef = this._dialog.open(DeleteConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._subscriptionApiService
          .delete(this.id)
          .pipe(take(1))
          .subscribe(
            () => {
              this.alertForm.reset();
              this.showSuccessNotification(deleteSuccessText);
              this._router.navigate(['/']);
            },
            () => {
              this.showErrorNotification(deleteErrorText);
            }
          );
      }
    });
  }

  showSuccessNotification(title: string) {
    this._notifications.openSuccess(title, '', TOAST_MESSAGE_DURATION_SECONDS);
  }

  showErrorNotification(title: string) {
    this._notifications.openError(title, '', TOAST_MESSAGE_DURATION_SECONDS);
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.value == object2.id;
  }

  handleAlertTypeSelection(selectedAlertTypeOption: Record<string, string>[], severityOptionsByType: Record<string, any[]>) {
    const selectedAlertTypeId = selectedAlertTypeOption[0].value;
    if (this.alertTypesWithRadioButtons.includes(selectedAlertTypeId)) {
      if (this.alertForm.value.type !== selectedAlertTypeId) {
        // AAG-158: As the ticket's description, critical is selected by default
        // and we decided to store it at index 0 of the severity options
        const criticalSeverityOptionIndex = 0;
        const defaultSeverityOption = severityOptionsByType[selectedAlertTypeId][criticalSeverityOptionIndex];

        this.alertForm.patchValue({ severity: defaultSeverityOption.value });
      }
    }
  }

  addOrRemoveControlsBasedOnTypeSelection(value) {
    // Add severity control only if alert type has radio buttons
    if (this.alertTypesWithRadioButtons.includes(value)) {
      this.alertForm.addControl('severity', new FormControl('', [Validators.required]));
    } else {
      this.alertForm.removeControl('severity');
    }

    // Add unauthorized usage form control only when Unauthorized Vehicle Movement is selected as alert type
    if (value === this.unauthorizedUsageAlertTypeValue) {
      this.alertForm.addControl('unauthorizedUsageDistance', new FormControl('0', [Validators.required]));
    } else {
      this.alertForm.removeControl('unauthorizedUsageDistance');
    }
  }

  openCustomScheduleDialog() {
    const { startTime, endTime } = this.alertForm.value;
    const initialStartTime = startTime;
    const initialEndTime = endTime;
    this._dialog
      .open(CustomScheduleDialogComponent, {
        data: { startTime, endTime, isUpdateMode: startTime && endTime },
        panelClass: 'custom-schedule-dialog'
      })
      .afterClosed()
      .pipe(filter((data) => !!data))
      .subscribe((result) => {
        const { startDay, startTime, endDay, endTime, isCustomScheduleDeleted } = result;
        const newStartTime = `${startDay} ${startTime}`;
        const newEndTime = `${endDay} ${endTime}`;
        const patchedValue = isCustomScheduleDeleted ? { startTime: '', endTime: '' } : { startTime: newStartTime, endTime: newEndTime };
        this.alertForm.patchValue(patchedValue);
        // Mark the form as dirty to reflect the changes
        if (initialStartTime !== newStartTime || initialEndTime !== newEndTime || isCustomScheduleDeleted) {
          this.alertForm.markAsTouched();
          this.alertForm.markAsDirty();
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }

  omitNumbersFromInput(event) {
    var key;
    key = event.charCode;
    return key > 47 && key < 58;
  }

  handleAssetDropdownChange(e) {
    if (e.isAllSelected) {
      this.alertForm.patchValue({ assets: null, allAssets: true });
      this.alertForm.get('assets').clearValidators();
    } else {
      this.alertForm.patchValue({ assets: e.selected, allAssets: false });
      this.alertForm.get('assets').setValidators([Validators.required]);
    }
    // Need to manually mark this as touched/dirty since we are now using a hidden form control
    this.alertForm.controls['assets'].markAsTouched();
    this.alertForm.controls['assets'].markAsDirty();
    this.alertForm.get('assets').updateValueAndValidity();
    this.alertForm.updateValueAndValidity();
  }
}
