import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';
import { difference, groupBy } from 'lodash';

import { PermissionsService } from '@zonar-ui/auth';
import { Division } from '@zonar-ui/auth/lib/models/core-company-api.model';
import { UserProfile } from '@zonar-ui/auth/lib/models/core-user-api.model';

import { AUTH_PERMISSIONS } from '@environments/shared';
import { ENV } from '@environments/environment.provider';

const adminPermPatternBuilder = (companyId: string) => `:${companyId}:::${AUTH_PERMISSIONS.MANAGE_ALERTS}`;

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  isAnyAdmin$ = new ReplaySubject<boolean>(1);
  isManagedThresholds$ = new ReplaySubject<boolean>(1);

  adminThresholdProfiles$ = new ReplaySubject<Record<string, UserProfile[]>>(1);

  constructor(
    @Inject(ENV) private env: any,
    private _httpClient: HttpClient,
    private _permissionsService: PermissionsService,
  ) { }

  initialize(companyId: string) {
    this._permissionsService.isZonarUser$.pipe(
      switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfCompanyAdmin$(companyId))),
    ).subscribe((isAdmin) => {
      this.isAnyAdmin$.next(isAdmin);
    });

    this.hasThresholdSettingsPermission(companyId).subscribe((isManagedThresholds) => {
      this.isManagedThresholds$.next(isManagedThresholds);
    });
  }

  getThresholdAdminProfiles(userId: string): Observable<Record<string, UserProfile[]>> {
    const { applicationId, adminRoleId } = this.env.thresholdSettingsBase;
    return this._httpClient.get<UserProfile[]>(
      `${this.env.coreEntityApiBase.url}/userprofiles/?userId=${userId}&applicationId=${applicationId}&roleId=${adminRoleId}`
    ).pipe(
      retry(3),
      map((userProfiles) => groupBy(userProfiles, 'companyId')),
    );
  }

  private hasThresholdSettingsPermission(companyId: string): Observable<boolean> {
    return this.adminThresholdProfiles$.pipe(
      map((mapperByCompanyId) => !!mapperByCompanyId[companyId]),
    );
  }

  /**
   * If the user is a company admin, then they need to match these criteria in order to be granted admin permissions:
   * 1. `roles` array needs to contain object which has `id` property equal to the adminRole parameter in the environment file
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   * 3. `applicationId` matches current application's ID
   * 4. `isAllDivisions$` value needs to be `true`
   * @param companyId the currently selected company on the UI
   */
  private checkIfCompanyAdmin$(companyId): Observable<boolean> {
    return combineLatest([
      this._permissionsService.hasPermission(adminPermPatternBuilder(companyId)),
      this.isLegacyDivisionsAll(companyId)
    ]).pipe(
      map(([isAdminRole, isLegacyDivisionsAll]) => isAdminRole && isLegacyDivisionsAll),
    );
  }

  /**
   * Select current profile and check all divisions are LEGACY type
   * 
   * @param companyId the currently selected company on the UI
   */
  private isLegacyDivisionsAll(companyId: string): Observable<boolean> {
    return combineLatest([
      this.getLegacyDivisionsByCompanyId(companyId),
      this._permissionsService.getUserProfileByCompanyId$(companyId),
    ]).pipe(
      map(([legacyDivisions, userprofile]) => difference(legacyDivisions, userprofile.divisions).length === 0),
      );
  }

  /**
   * Get the LEGACY divisions list from selected company
   * 
   * @param companyId the currently selected company on the UI
   */
  private getLegacyDivisionsByCompanyId(companyId: string): Observable<string[]> {
    return this._permissionsService.divisionMap$.pipe(
      map((divisionMap) => this.convertToLegacyDivisions(companyId, divisionMap)),
    );
  }

  /**
   * Logic filter legacy division
   * 
   * @param companyId the currently selected company on the UI
   * @param divisionMap The all division that built into divisionId:divisionObject before
   */
  private convertToLegacyDivisions(companyId, divisionMap): string[] {
    return Object.values(divisionMap).reduce((acc: string[], division: Division) => {
      if (division.companyId === companyId && division.type === 'LEGACY') {
        acc.push(division.id);
      }
      return acc;
    }, []) as string[];
  }
}
