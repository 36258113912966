import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventType, Entity, RecipientBody, SubscriptionFormData, AlertTypeList, SubscriptionRequestBody } from '@models/subscription.model';
import { ENV } from '@environments/environment.provider';
import { httpRetryCount } from '@environments/shared';
import { retry } from 'rxjs/operators';
import { UNAUTHORIZED_USAGE_ALERT_TYPE } from '@app/constants';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiHttpService {
  constructor(@Inject(ENV) private env: any, private _httpClient: HttpClient) {}

  getSubscriptionsByCompanyId(companyId: string): Observable<any> {
    const SUBSCRIPTION_API_URL = `${this.env.apiBase.url}/event-subscription?status=ACTIVE,INACTIVE&companyId=${companyId}`;
    return this._httpClient
      .get<any>(SUBSCRIPTION_API_URL)
      .pipe(map((subscriptions) => subscriptions.sort(this.nestedSort('eventType', 'name'))))
      .pipe(retry(httpRetryCount));
  }

  nestedSort =
    (prop1, prop2 = null, direction = 'asc') =>
    (e1, e2) => {
      const a = prop2 ? e1[prop1][prop2].toLowerCase() : e1[prop1].toLowerCase(),
        b = prop2 ? e2[prop1][prop2].toLowerCase() : e2[prop1].toLowerCase(),
        sortOrder = direction === 'asc' ? 1 : -1;
      return a < b ? -sortOrder : a > b ? sortOrder : 0;
    };

  getSubscriptionById(id: string): Observable<any> {
    const SUBSCRIPTION_API_URL = `${this.env.apiBase.url}/event-subscription?guid=${id}`;
    return this._httpClient.get<any>(SUBSCRIPTION_API_URL).pipe(retry(httpRetryCount));
  }

  put(id: string, subscription: SubscriptionFormData, companyId: string): Observable<any> {
    const SUBSCRIPTION_API_URL = `${this.env.apiBase.url}/event-subscription/${id}`;
    const body = this._buildSubscriptionBody(subscription, companyId);
    return this._httpClient.put<any>(SUBSCRIPTION_API_URL, body);
  }

  post(subscription: SubscriptionFormData, companyId: string): Observable<any> {
    const SUBSCRIPTION_API_URL = `${this.env.apiBase.url}/event-subscription`;
    const body = this._buildSubscriptionBody(subscription, companyId);
    return this._httpClient.post<any>(SUBSCRIPTION_API_URL, body);
  }

  delete(documentId: string): Observable<any> {
    const DELETE_API_URL = `${this.env.apiBase.url}/event-subscription/${documentId}`;
    return this._httpClient.delete<any>(DELETE_API_URL);
  }

  getAllAlertTypes(companyId: string): Observable<AlertTypeList> {
    const SUBSCRIBABLE_EVENT_API_URL = `${this.env.apiBase.url}/subscribable-events?companyId=${companyId}`;
    return this._httpClient.get<AlertTypeList>(SUBSCRIBABLE_EVENT_API_URL).pipe(retry(httpRetryCount));
  }

  private _generateCustomSchedule(startTime: string, endTime: string): any {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const customStartTime = this._convertToUTCTime(startTime, userTimezone);
    const customEndTime = this._convertToUTCTime(endTime, userTimezone);

    const [startDay, startTimeValue] = customStartTime.split(' ');
    const [endDay, endTimeValue] = customEndTime.split(' ');

    return {
      customStartTime: { day: startDay, time: startTimeValue },
      customEndTime: { day: endDay, time: endTimeValue }
    };
  }

  private _convertToUTCTime(dayTime: string, userTimezone: string): string {
    const [day, time, meridiem] = dayTime.split(/\s+/);
    // Assuming `time` is in "h:mm" format and needs to be converted to "HH:mm" format for moment parsing.
    const formattedTime = moment(`${time} ${meridiem}`, 'h:mm A').format('HH:mm');
    // Create a moment object for the local time zone based on the given day, time, and user's timezone.
    const localDateTime = moment.tz(`${day} ${formattedTime}`, 'dddd HH:mm', userTimezone);
    // Convert the local moment object to UTC.
    const utcDateTime = localDateTime.utc();
    return `${utcDateTime.format('dddd')} ${utcDateTime.format('HH:mm')}`;
  }

  private _buildSubscriptionBody(subscription: SubscriptionFormData, companyId: string) {
    const eventType: EventType = { id: subscription.type, name: subscription.name };

    const entities: Array<Entity> = [];
    if (!subscription.allAssets) {
      subscription.assets.forEach((asset) => {
        entities.push({ id: asset, type: 'assets' });
      });
    }

    const deliveryMethods: Array<string> = [];
    deliveryMethods.push('Email');

    const recipientsArray: Array<RecipientBody> = [];
    subscription.recipients.forEach((recipient) => {
      recipientsArray.push({ id: recipient, type: 'users' });
    });

    const subscriptionStatus = subscription.isActive ? 'ACTIVE' : 'INACTIVE';

    const subscriptionEventSelection = !!subscription.severity ? [{ field: 'severity', activeSelectorId: subscription.severity }] : [];

    const subscriptionBody: any = {
      companyID: companyId,
      eventType: eventType,
      entities: entities,
      deliveryMethods: deliveryMethods,
      recipients: recipientsArray,
      status: subscriptionStatus,
      eventSelection: subscriptionEventSelection,
      allAssets: subscription.allAssets
    };

    if (subscription.type === UNAUTHORIZED_USAGE_ALERT_TYPE) {
      subscriptionBody.configParams = {
        distanceTravelled: Number(subscription.unauthorizedUsageDistance)
      };
    }

    if (subscription.startTime && subscription.endTime && subscription.startTime !== '' && subscription.endTime !== '') {
      const customSchedule = this._generateCustomSchedule(subscription.startTime, subscription.endTime);
      subscriptionBody.customSchedule = customSchedule;
    } else {
      subscriptionBody.customSchedule = null;
    }
    return subscriptionBody;
  }
}
