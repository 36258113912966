import { Component, Inject } from '@angular/core';
import { AdminService } from '@app/services/admin.service';
import { ENV } from '@environments/environment.provider';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-all-alerts-page',
  templateUrl: './all-alerts.page.component.html',
  styleUrls: ['./all-alerts.page.component.scss']
})
export class AllAlertsPageComponent {
  private _onDestroy$: Subject<void> = new Subject<void>();
  isAdmin$ = this._adminService.isAnyAdmin$;
  alertsLink: string = this.env.activityFeedBase;
  thresholdSettingsLink: string = this.env.thresholdSettingsBase.link;
  isManagedThresholds$ = this._adminService.isManagedThresholds$;
  displayCTSLink;

  constructor(@Inject(ENV) private env: any, 
    private _adminService: AdminService,
    private _featureToggleService: FeatureToggleService) {}

  ngOnInit() {
    this._featureToggleService.initializeDevCycle();
    this._featureToggleService
      .isFeatureEnabled('cts-admin-link')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(flag => (this.displayCTSLink = flag));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}
