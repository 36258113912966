<span class="container" *ngIf="data$ | async as data; else loading">
  <span *ngIf="data.alerts && data.alerts.length > 0; else emptyMessage">
    <app-alert-info-row
      *ngFor="let alert of data.alerts"
      [alert]="alert"
      [alertTypes]="data.alertTypes"
      [assetsTotal]="data.assetsTotal"
    ></app-alert-info-row>
  </span>
</span>
<ng-template #loading
  ><div class="loading-container"><mat-spinner></mat-spinner></div
></ng-template>
<ng-template #emptyMessage>
  <div class="flex flex-column items-center u-text--gray empty-message">
    <div class="header u-margin__bottom--small">No Current Alerts</div>
    <div class="description">Create alerts to help keep your users up to date with the latest fleet changes</div>
  </div>
</ng-template>
