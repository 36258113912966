import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { AlertTypeList } from '@app/models';
import { AssetApiHttpService } from '@app/services/asset-api.http.service';
import { CompanyService } from '@app/services/company-store.service';
import { SubscriptionApiHttpService } from '@app/services/subscription-api.http.service';

@Component({
  selector: 'app-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss']
})
export class AlertsListComponent implements OnInit {
  data$: Observable<{
    alerts: any;
    alertTypes: AlertTypeList;
    assetsTotal: number;
  }>;

  constructor(
    private subscriptionService: SubscriptionApiHttpService,
    private companyService: CompanyService,
    private assetService: AssetApiHttpService,
  ) {}

  ngOnInit(): void {
    this.companyService.currentCompany$.pipe(
      filter((company) => !!company),
    ).subscribe((company) => {
      this.data$ = combineLatest([
        this.subscriptionService.getSubscriptionsByCompanyId(company.value),
        this.subscriptionService.getAllAlertTypes(company.value),
        this.assetService.getAssetsFromCache().pipe(map((allAssets) => allAssets?.length || 0), catchError(() => of(0))),
      ]).pipe(
        map(([alerts, alertTypes, assetsTotal]) => ({ alerts, alertTypes, assetsTotal })),
      );
    });
  }
}
