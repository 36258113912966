<div class="flex flex-row justify-start items-center">
  <a [href]="alertsLink" class="u-text--blue u-text--small">&#60; Back to Alerts</a>
</div>
<div class="flex flex-row justify-between u-margin__bottom--large items-center">
  <h1>Manage Alerts</h1>
  <button *ngIf="isAdmin$ | async" mat-flat-button type="text" color="accent" routerLink="add">New Alert</button>
</div>

<div *ngIf="isManagedThresholds$ | async" class="page-description u-text--black-base u-margin__bottom--large">
  Manage the alerts for your entire company here. Assign alerts to persons responsible for different areas of your business.
  <ng-container *ngIf="displayCTSLink">
    If you would like to manage your severity levels, 
    visit the <a class="u-text--blue u-text--bold" [href]="thresholdSettingsLink">threshold settings page.</a>
  </ng-container>
</div>

<app-alerts-list></app-alerts-list>
