import { Component, Input, OnInit } from '@angular/core';
import { COMPANIES_ENTITY_TYPE } from '@app/constants';
import { Alert, AlertTypeList } from '@app/models';
import { AdminService } from '@app/services/admin.service';

@Component({
  selector: 'app-alert-info-row',
  templateUrl: './alert-info-row.component.html',
  styleUrls: ['./alert-info-row.component.scss']
})
export class AlertInfoRowComponent implements OnInit {
  @Input() alert: Alert;
  @Input() alertTypes: AlertTypeList;
  @Input() assetsTotal: number = 0;

  typeOptionsMap = {};
  isAdmin$ = this._adminService.isAnyAdmin$;

  constructor(private _adminService: AdminService) {}

  ngOnInit() {
    this.typeOptionsMap = this._buildTypeOptionsMap();

    // TODO: Remove this when https://zonarsystems.atlassian.net/browse/AA-611 has been completed
    this.alert = this._filterOutCompanyEntity(this.alert);
  }

  private _buildTypeOptionsMap() {
    return this.alertTypes.results.reduce((acc, alertType) => ({
      ...acc,
      [alertType._source.event_type]: alertType._source.name,
    }), {});
  }

  // TODO: Remove this when https://zonarsystems.atlassian.net/browse/AA-611 has been completed
  private _filterOutCompanyEntity(alert) {
    const updatedEntities = alert.entities.filter((entity) => entity.type !== COMPANIES_ENTITY_TYPE);
    return { ...alert, entities: updatedEntities };
  }
}
