export const environment = {
  name: 'staging-na',
  region: 'NA',
  production: false,
  adminRole: '224442cb-be73-48d6-8b50-df826b2f1447',
  appUrl: 'https://alertmanagement.staging.zonarsystems.net',
  apiBase: {
    url: 'https://event-subscription-api.staging.zonarsystems.net/v1'
  },
  gtcxMapsBase: 'https://maps.zonarsystems.net',
  activityFeedBase: 'https://activityfeed.staging.zonarsystems.net/',
  thresholdSettingsBase: {
    link: 'https://settings.staging.zonarsystems.net/',
    applicationId: 'c607bca3-5909-4b0a-8044-456ed8f63ed9',
    adminRoleId: 'c6be2c4f-ac90-4a3b-bcfa-47d125e1effb'
  },
  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  authEnv: 'production',
  auth: {
    clientID: 'RESqGm4uTpFqAspMKsWDuB83AI42LndP',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    applicationId: 'a5ecb514-f4f4-4f43-9d75-bc685c346b6b',
    defaultZonarRole: '224442cb-be73-48d6-8b50-df826b2f1447',
    useRefreshTokens: true
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },
  datadog: {
    enabled: true,
    applicationId: 'c6726179-145e-4a20-9043-f23f3c26a956',
    clientToken: 'pub7d9e4520371e38855c316f364e250dd2',
    siteUrl: 'us5.datadoghq.com',
    env: 'staging'
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'de-DE', 'en-GB', 'it', 'fr-FR', 'de', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  devCycle: {
    clientId: 'dvc_client_0c8f57e7_4bcf_4324_9392_906c70568cf2_fe3fe36'
  }
};
